<script>
import LoggerStatusIcon from '@/components/LoggerStatusIcon.vue';

import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPopup,
  LFeatureGroup,
} from 'vue2-leaflet';

export default {
  name: 'LoggerSelectPageMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LFeatureGroup,
    LoggerStatusIcon,
  },
  data() {
    return {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 4,
      bounds: null,
      center: [48.3544, 99.9981],
      oldCenter: null,
      oldZoom: null,
      itemsPerPage: 5,
      currentPage: 1,
      tableFields: [
        {
          key: 'serial',
          label: 'Serial No',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'id',
          label: '',
        },
      ],
    };
  },
  methods: {
    showAll() {
      this.$refs.loggerMap.mapObject
        .fitBounds(this.$refs.fg.mapObject.getBounds(), { padding: [50, 50] });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    resetMapAfterHover() {
      this.$refs.loggerMap.mapObject.setView(this.oldCenter, this.oldZoom);
      this.oldCenter = null;
      this.oldZoom = null;
    },
    zoomMapOnHover(point) {
      if (!this.oldCenter || !this.oldZoom) {
        this.oldCenter = this.center;
        this.oldZoom = this.zoom;
      }
      this.$refs.loggerMap.mapObject.setView(point.location, 12);
    },
  },
  computed: {
    mapPoints() {
      return this.$store.getters['loggers/list']
        .filter(logger => !!logger.location);
    },
    loggerCenter() {
      // If we actually have some locations, centre the map on them
      if (this.mapPoint && this.mapPoint.length > 0) {
        const sumX = this.mapPoints.reduce((prev, curr) => prev + curr.location[0], 0);
        const sumY = this.mapPoints.reduce((prev, curr) => prev + curr.location[1], 0);
        return [sumX / this.mapPoints.length, sumY / this.mapPoints.length];
      }

      // If we don't, centre the map on the USA
      return [39.828724, -98.579479];
    },
  },
  mounted() {
    this.center = this.loggerCenter;
    this.$nextTick(() => {
      this.showAll();
    });
  },
};
</script>

<template>
  <b-row>
    <b-col cols=12 md=7 class='mb-2'>
      <l-map
        ref="loggerMap"
        style="height: 470px; width: 100%"
        :zoom="zoom"
        v-bind:center="center"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated">
        <l-tile-layer :url='url' />
        <!-- Begin Map Marker Loop -->
        <l-feature-group ref='fg'>
          <l-marker
            v-for="logger in mapPoints"
            :key='logger.id'
            :visible=true
            :lat-lng='logger.location'
            >
            <l-popup class="overflow-auto">
              <p><b>{{ logger.name || logger.serial }}</b></p>
              <logger-status-icon :status='logger.status' :alarm='logger.alarm' size="1x"/>
              <br />
              <b-btn style='width:100%' variant='primary' @click="$emit('view-logger', logger.id)">
                View
              </b-btn>
            </l-popup>
            <l-tooltip :content='logger.name || logger.serial' />
          </l-marker>
        </l-feature-group>
        <!-- End Marker Loop-->
      </l-map>
      <b-btn block
          variant='primary'
          @click='showAll()'
          >
          Show All On Map
      </b-btn>
    </b-col>
    <b-col cols=12 md=5>
      <b-container>
        <b-card style='height: 100%; padding-bottom: 0px' >
          <!-- Individual Logger Cards -->
          <div v-if='mapPoints && mapPoints.length > 0' class='lead'>
            <b-table
              id="loggerLocationList"
              :items="mapPoints"
              :fields="tableFields"
              :current-page="currentPage"
              :per-page="itemsPerPage"
              hover="true"
              @row-hovered="zoomMapOnHover(arguments[0])"
              @row-unhovered="resetMapAfterHover()"
              responsive
            >
            <template v-slot:cell(status)="data">
              <logger-status-icon :status='data.item.status' :alarm='data.item.alarm' size="1x" />
            </template>
            <template v-slot:cell(id)="data">
              <b-btn variant='primary' @click="$emit('view-logger', data.item.id)">View</b-btn>
            </template>
            </b-table>
          </div>
          <!-- Div displayed when there are no loggers with locations -->
          <div v-else class='lead text-center'>
            No logger locations available
          </div>
          <!-- End of Individual Logger Cards -->
          <!-- Footer of Logger List -->
          <template v-slot:footer>
            <b-container fluid>
              <b-row align-h='center' no-gutters>
                <b-col cols='auto'>
                  <!-- Table Page Selector -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows= "mapPoints.length"
                    :per-page="itemsPerPage">
                  </b-pagination>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </b-card>
      </b-container>
    </b-col>
  </b-row>
</template>
